



































































































import { isEqual } from 'lodash'
import { Component, Mixins, Ref } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// components
import Confirmation from '@/components/modals/Confirmation.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import NoDataFound from '@/components/NoDataFound.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
// types
import { IUsefulMaterialsFilter, NameValueResource, ShortUsefulMaterial } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'
import { convertDateToMSK, parseDateToMilliseconds } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    DateInput,
    NoDataFound,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class DepositoryCourseItemMaterials extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private footerOptions = tableFooterOptions
  private hoveredRowIndex = -1

  private courseID = this.$route.params.courseID

  private get filter() {
    return ManagerDepositoryModule.materialsFilter
  }

  private set filter(filter: IUsefulMaterialsFilter) {
    if (!isEqual(filter, this.filter) || this.materials.length === 0) {
      ManagerDepositoryModule.setMaterialsFilter(filter)
      this.fetchUsefulMaterials()
    }
  }

  private get materialTypes(): NameValueResource[] {
    return DictionaryModule.usefulMaterialsTypes
  }

  private get headers () {
    return [
      { text: '#', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Тип', value: 'usefulMaterialType' },
      { text: 'Дата', value: 'publishDatetime' },
      { text: '', value: 'actions' },
    ]
  }

  private get materials(): ShortUsefulMaterial[] {
    return ManagerDepositoryModule.materials.data
  }

  private get pagination () {
    return ManagerDepositoryModule.materials.meta
  }

  private get materialTypesName() {
    const types: { [key: string]: string } = {}
    this.materialTypes.map(type => types[type.value] = type.name)
    return types
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    this.fetchUsefulMaterials()
  }

  private handleHoverOver(id: number) {
    this.hoveredRowIndex = this.materials.findIndex(_item => _item.id === id)
  }

  private handleTouch(id: number) {
    this.hoveredRowIndex = -1
    this.hoveredRowIndex = this.materials.findIndex(_item => _item.id === id)
  }

  private handleEditMaterial(material: ShortUsefulMaterial) {
    this.$router.push({
      name: 'manager.bank.depository.courses.item.materials.edit',
      params: { courseID: this.courseID, materialID: material.id.toString() },
    })
  }

  private confirmDeleteMaterial(material: ShortUsefulMaterial) {
    this.confirm.open(
      'Удаление материала',
      `Вы действительно хотите удалить материал (<span class="text-body-3 secondary--text">${material.title}</span>)?`,
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDeleteMaterial(material)
      })
      .catch(() => {return})
  }

  private handleDeleteMaterial(material: ShortUsefulMaterial) {
    ManagerDepositoryModule.removeMaterial({ courseId: +this.courseID, materialId: material.id })
      .then(() => {
        if (this.materials.length === 1) {
          this.filter.page = this.filter.page > 1 ? this.filter.page - 1 : 1
        }
        this.fetchUsefulMaterials()
        this.notifySuccess('Материал удален успешно.')
      })
      .catch(this.notifyError)
  }

  private viewMaterial(material: any) {
    if (!this.hasSomePermissions([this.Permission.DEPOSITORY_MATERIALS_VIEW])) {
      this.notifyError('У Вас нет прав на просмотр материала.')
    } else {
      this.$router.push({
        name: 'manager.bank.depository.courses.item.materials.view',
        params: { courseID: this.courseID, materialID: material.id },
      })
    }
  }

  private handleFilter(field: string, value: any) {
    this.filter = field === 'publishDate' ? {
      ...this.filter,
      [field]: value ? convertDateToMSK(`${value} 00:00:00`, this.isLocalTimezone) : value,
      page: 1,
    } : {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private isPassedDate(date: string): boolean {
    return parseDateToMilliseconds(date) < Date.now()
  }

  @Bind
  @Debounce(300)
  private fetchUsefulMaterials() {
    ManagerDepositoryModule.fetchUsefulMaterials({ courseId: +this.courseID })
      .catch(this.notifyError)
  }
}
